<template>
	<div>
		<div class="tabs-wrapper">
			<q-tabs
				indicator-color="primary"
				align="justify"
				active-color="primary"
			>
				<q-route-tab
					v-for="(tab, index) in tabs"
					:key="index"
					:to="{ name: tab.path_name }"
					:label="tab.label"
				/>
			</q-tabs>
		</div>

		<div class="router-view-wrapper">
			<div>
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import store from '@/store/store.js';

export default {
	name: "settings",
	meta: {
		title: `Alert Cascade - ${store.getters.module_name} Settings`
	},
	data() {
		return {
			tabs: [
				{
					path_name: 'Incident Rules',
					label: 'Rules',
				},
			],
			customer_permission: ''
		};
	},

	methods: {
		createTabData(){
			if (this.customer_permission === 'ADMIN'){
				this.tabs.unshift(
					{
						path_name: 'Incident Fields',
						label: 'Fields',
					},
					{
						path_name: 'Incident Forms',
						label: 'Forms',
					},
				)
				if (this.$store.getters.current_CG.root_customer_group_permissions.indexOf('Anonymous web reporting') != -1){
					this.tabs.push(
						{
							path_name: 'Incident Templates',
							label: 'Templates',
						},
					)
				}
			}
			if (window.location.pathname == '/ui/incidents/settings'){
				if (this.customer_permission == 'OPERATOR'){
					this.$router.push({'name': 'Incident Rules'})
				}else if (this.customer_permission == 'ADMIN'){
					this.$router.push({'name': 'Incident Fields'})
				}
			}
		}
	},
	watch:{
		$route (to, from){
			if (to.name == 'Incident Settings'){
				if (this.customer_permission == 'OPERATOR'){
					this.$router.push({'name': 'Incident Rules'})
				}else if (this.customer_permission == 'ADMIN'){
					this.$router.push({'name': 'Incident Fields'})
				}
			}
		}
	},
	created() {
		this.customer_permission = this.$store.getters.logged_user.customer_permission
		this.createTabData()
	}
};
</script>
<style lang="scss" scoped>
.tabs-wrapper {
	position: sticky;
	background: #fff;
	border: 1px solid $grey-3;
	z-index: 5;
}
.router-view-wrapper {
	padding: 1.5rem 3rem;
	background-color: #fff;
	@media (max-width: 768px) {
		padding: 1rem 0.5rem;
	}
}
</style>
<style lang="scss">
	.settings-wrapper {
		text-align: left;
		border-radius: 0.375rem;
		padding: 0 1rem 0 1rem;
		margin-bottom: 1rem;
		width: 100%;
		background: $grey-2;
		border: 1px solid $grey-4;
	}
	.setting-title {
		font-size: 1.5rem;
		font-weight: 400;
		line-height: 2rem;
		letter-spacing: normal;
		margin-top: 16px;
		margin-bottom: 16px;
		padding-bottom: 16px;
		text-align: left;
		border-bottom: 1px solid #e0e0e0;
		cursor: default;
	}

	.field-fab-wrapper {
		position: sticky;
		bottom: 20px;
		z-index: 5;
		width: fit-content;
		margin: 0 auto;
	}
</style>
